
/* phone */
#business {
  padding: 0px 0px 20px 0px;
    background-image: url("../../assets/business/bussiness-bg-1.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .business-container {
    position: relative !important;
  }
  .business-container::after {
    position: absolute !important;
    top: 30px;
    left: 20px;
   content: url(./../../assets/customers/star.svg);
  }
  .background-business-one {
    position: relative;
    height: 350px;
    width: 250px;
    left: -20px;
    top: 50px;
    background-image: url("./../../assets/customers/one_bg.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .business-details-div {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 22px;
    text-align: start;
    width: 270px;
    height: 300px;
  }
  .business-heading {
    font-family: poppins;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    background: linear-gradient(316deg, #ff3d00 4%, #fcb045 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: start;
  }
  .business-details {
    display: flex;
    gap: 8px;
    font-family: poppins;
    font-weight: 500;
    font-size: 18px;
  }
  
  .lap-container {
    display: flex;    
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  } 
  .circle-system {
  position: relative;
  width: 300px;
  height: 300px;
  background-image: url("./../../assets/business/Ellipse.svg");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
  }
  .system {
    position: absolute;
    width: 100%;
    top: 100px;
    right: 0px;
  
  }

  
  /* large */
  
  @media (min-width: 900px){
    #business {
      padding: 0px 0px 150px 0px;
    }
    .phone-img {
      width: 584px;
      height: 755px;
      left: 120px;
      top: 40px;
    }
    .background-business-one {
      width: 300px;
      height: 555px;
      
    }
    .circle-system {
      position: relative;
      width: 600px;
      height: 600px;
      background-image: url("./../../assets/business/Ellipse.svg");
      background-size: contain;
      background-repeat: no-repeat;
      }
      .system {
        position: absolute;
        width: 100%;
        top: 200px;
        right: -50px;
      }
      .business-container::after {
        position: absolute !important;
        top: 30px;
        left: -50px;
        content: url(./../../assets/customers/star1.svg);
      }
  
  }
  
  
  @media (min-width: 662px) {
    .phone-img {
      width: 584px;
      height: 755px;
    }
    .background-business-one {
      width: 600px;
      height: 400px;
      top: 20px;
    }
    .business-details-div {
      justify-content: center;

    }
    .circle-system {
      position: relative;
      width: 600px;
      height: 600px;
      background-image: url("./../../assets/business/Ellipse.svg");
      background-size: contain;
      background-repeat: no-repeat;
      }
      .system {
        position: absolute;
        width: 100%;
        top: 200px;
        right: -50px;
      }
} 
  @media (min-width: 1024px) {
    .phone-img {
      width: 564px;
      height: 735px;
      left: 20px;
      top: 40px;
    }
    .business-container::after {
      position: absolute !important;
      top: 110px;
      left: 60px;
     content: url(./../../assets/customers/star3.svg);
    }
    .background-one {
      width: 300px;
      height: 400px;
      top: 150px;
    }
   
    .business-details-div {
      justify-content: center;
      left: 50px;
    }
    .circle-system {
      position: relative;
      width: 600px;
      height: 600px;
      background-image: url("./../../assets/business/Ellipse.svg");
      background-size: contain;
      background-repeat: no-repeat;
      }
      .system {
        position: absolute;
        width: 100%;
        top: 200px;
        right: -50px;
      }
      .business-container::after {
        position: absolute !important;
        top: 120px;
        left: 50px;
       content: url(./../../assets/customers/star3.svg);
      }
  }