/* mobile  */

@media screen and (max-width: 450px) {
  .banner-text {
    /* top: 15% !important; */
  }
  .one,
  .scribe {
    font-size: 1.12rem !important;
  }
  .scribe {
    transform: translate(-109%, 22%) !important;
  }
  .quote-img img {
    width: 32% !important;
  }
  .allyour {
    font-size: 1rem !important;
  }
  .second-line,
  .third-line {
    font-size: 14px !important;
  }
  .join-morebtn {
    margin-top: 1px !important;
  }
  .join-now {
    font-size: 12px !important;
    padding: 0px 10px !important;
  }
  .downarrow {
    top: 83% !important;
  }
  .arrow {
    width: 12px !important;
  }
  .arrow2 {
    margin-top: -15px !important;
  }
}

@media screen and (min-width: 1360px) and (max-width: 13900px) {
  .banner-text {
    transform: translateY(-18%) !important;
  }
}

/* mac  */

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .banner-text {
    transform: translateY(-38%) !important;
  }
}

/* fullhd */

@media screen and (min-width: 1900px) and (max-width: 2000px) {
  .banner-text {
    transform: translateY(-18%) !important;
  }
}

#banner {
  position: relative;
  margin-top: 73px;
}

.banner-section {
  position: relative;
}

.banner-img {
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
}

.banner-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
}

.one {
  color: #131313;
  font-family: "Maitree";
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.scribe {
  color: #fafafa;
  font-family: "Maitree";
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transform: translate(-111%, 22%);
  position: absolute;
}

.allyour,
.second-line {
  color: #131313;
  font-family: "Maitree";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
}

.quote-img {
  position: relative;
}

.great {
  font-weight: 600;
}

.third-line {
  color: #131313;
  font-family: "Maitree";
  font-size: 2.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.join-now {
  color: #131313 !important;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid #131313 !important;
  padding: 5px 20px;
  background-color: transparent !important;
}

.join-morebtn {
  margin-top: 30px;
}

.downarrow {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.downarrow .arrow1,
.downarrow .arrow2 {
  -webkit-animation: mouse-scroll 1.5s infinite;
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.downarrow .arrow2 {
  -webkit-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
}

.quote-image,
.arrow {
  user-select: none;
  -webkit-user-drag: none;
}
