/* mobile  */

@media screen and (max-width: 900px) {
  .disclaimer-bg {
    text-align: justify !important;
  }
}

.disclaimer-bg {
  background: #ebf4ff;
  color: #131313;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 0px;
}

.disclaimer-bg span {
  font-weight: 600;
}
