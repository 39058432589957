/* mobile  */

@media screen and (min-width: 320px) and (max-width: 400px) {
  .mobile {
    justify-content: center !important;
    align-items: center !important;
    column-gap: 5px !important;
  }
  .mobile1 {
    width: 35% !important;
  }
  .mobile2 {
    width: 50% !important;
  }
  .about-desc {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 28px !important;
  }
  .about-head {
    margin-bottom: 15px !important;
  }
  .about-col {
    margin-bottom: 50px !important;
  }
  .about-row {
    padding-bottom: 40px !important;
    padding-top: 45px !important;
  }
  .down-arrow {
    height: 11% !important;
  }
  .about-one {
    width: 58% !important;
  }
}

@media screen and (min-width: 391px) and (max-width: 450px) {
  .mobile {
    justify-content: center !important;
    align-items: center !important;
  }
  .mobile1 {
    width: 35% !important;
  }
  .mobile2 {
    width: 50% !important;
  }
  .about-desc {
    text-align: center !important;
    font-size: 14px !important;
    line-height: 28px !important;
  }
  .about-head {
    margin-bottom: 15px !important;
  }
  .about-col {
    margin-bottom: 50px !important;
  }
  .about-row {
    padding-bottom: 40px !important;
    padding-top: 45px !important;
  }
  .down-arrow {
    height: 12% !important;
  }
  .about-one {
    width: 48% !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .mobile {
    justify-content: center !important;
    align-items: center !important;
  }
  .mobile1 {
    width: 35% !important;
  }
  .mobile2 {
    width: 50% !important;
  }
  .about-desc {
    text-align: center !important;
  }
  .about-head {
    margin-bottom: 15px !important;
  }
  .about-col {
    margin-bottom: 50px !important;
  }

  .about-row {
    padding-bottom: 40px !important;
  }
  .about-contents {
    transform: translateY(50%) !important;
  }
}

#about {
  background-image: url("../../assets/about/aboutbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position-x: right; */
  background-position-y: bottom;
  position: relative;
}

.about-data,
.about-one {
  position: relative;
}

.about-row {
  padding-bottom: 80px;
  padding-top: 100px;
}

.about-contents {
  position: absolute;
  top: 0;
  transform: translateY(25%);
}

.about-head {
  color: #2270d6;
  text-align: center;
  margin-bottom: 35px;
}

.about-desc {
  color: #131313;
  font-family: "Poppins";
  font-size: 1.12rem;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
}

.mobile {
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 15px;
  border-radius: 770.664px;
  background: radial-gradient(
    50.52% 50.52% at 51% 50%,
    #e6ae04 0%,
    rgba(255, 211, 79, 0) 51.79%
  );
  /* background-image: url("../../assets/about/mobilebg.svg");
  background-repeat: no-repeat; */
}

.down-arrow {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.mobile1,
.mobile2 {
  user-select: none;
  -webkit-user-drag: none;
}
