/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  #morefaq {
    background-size: cover !important;
    background-position-x: center !important;
  }
  .more-faq-head {
    padding-top: 30px !important;
    margin-bottom: 20px !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .search-bar {
    justify-content: center !important;
  }
  .search-icon {
    position: absolute;
    margin-top: 5px;
    left: 0px;
    z-index: 9999;
   }
}

@media screen and (min-width: 391px) and (max-width: 450px) {
  .more-faq-head {
    padding: 30px !important;
    margin-bottom: 20px !important;
  }
  #morefaq {
    background-size: cover !important;
    background-position-x: center !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .search-bar {
    justify-content: center !important;
  }
  .search-icon {
    position: absolute;
    margin-top: 5px;
    left: 0px;
    z-index: 9999;
   }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .more-faq-head {
    padding-top: 30px !important;
    margin-bottom: 20px !important;
  }
  #morefaq {
    background-size: cover !important;
    background-position-x: center !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .search-bar {
    justify-content: center !important;
  }
  .search-icon {
    position: absolute;
    margin-top: 0px;
    left: 0px;
    z-index: 9999;
   }
}

#morefaq {
  background-image: url("../../../assets/faq/faq.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 73px;
}

.more-faq-head {
  padding-top: 40px;
  margin-bottom: 25px;
}

.question {
  color: #040404;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  margin-left: 20px;
}

.answer {
  color: #131313;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-left: 20px;
}

.faq-view-more {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 50px;
  background: #f4b701 !important;
  outline: none;
  border: none;
  padding: 5px 20px;
}

.faq-btn {
  margin-top: 50px;
}

.more-faq-section {
  padding-bottom: 70px;
}

.accordion {
  --bs-accordion-border-color: none;
  --bs-border-radius: 10px;
  --bs-accordion-bg: #fafafa;
  --bs-accordion-btn-padding-x: 10px;
  --bs-accordion-btn-padding-y: 8px;
  --bs-accordion-btn-focus-box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background: #fafafa;
}

.accordion-button::after {
  background-image: url("../../../assets/faq/plus.svg");
  background-repeat: no-repeat;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../assets/faq/minus.svg");
  background-repeat: no-repeat;
}

.accordion-item {
  margin-bottom: 25px;
  background: #fafafa;
  border-left: 8px solid #f4b701;
  border-radius: 10px;
}

.faq-search {
  color: #fafafa;
  border-radius: 9px;
  background: #2270d6;
  outline: none;
  border: none;
  padding: 3px 15px;
  margin-left: 25px;
}

.search-bar {
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-field {
  background: #fafafa;
  height: 40px;
  border: none;
  outline: none;
  caret-color: #ffbe00;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.25);
  width: 1060px;
  height: 40px;

}

.search-icon {
 position: absolute;
 margin-top: 5px;
 left: 60px;
 z-index: 9999;
}
