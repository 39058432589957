/* mobile  */

@media screen and (min-width: 320px) and (max-width: 390px) {
  #faq {
    background-size: cover !important;
  }
  .faq-head {
    padding: 30px 0px 20px 0px !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .faq-container {
    background-size: 120px 70px !important;
  }
}

@media screen and (min-width: 391px) and (max-width: 450px) {
  .faq-head {
    padding: 30px 0px 20px 0px !important;
  }
  #faq {
    background-size: cover !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .faq-container {
    background-size: 120px 70px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .faq-head {
    padding: 30px 0px 20px 0px !important;
  }
  #faq {
    background-size: cover !important;
  }
  .question {
    line-height: 30px !important;
    margin-left: 10px !important;
  }
  .answer {
    margin-left: 10px !important;
  }
  .faq-container {
    background-size: 120px 70px !important;
  }
}

#faq {
  background-image: url("../../assets/faq/faq.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 100px;
}

.faq-head {
  padding: 40px 0px 25px 0px;
}

.faq-container {
  background-image: url("../../assets/faq/q1.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  
}

.faq-section {
  padding-top: 10px;
}

.question {
  color: #040404;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  margin-left: 20px;
}

.answer {
  color: #131313;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-left: 20px;
}

.faq-view-more {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 50px;
  background: #f4b701 !important;
  outline: none;
  border: none;
  padding: 5px 20px;
}

.faq-btn {
  margin-top: 50px;
}

.accordion {
  --bs-accordion-border-color: none;
  --bs-border-radius: 10px;
  --bs-accordion-bg: #fafafa;
  --bs-accordion-btn-padding-x: 10px;
  --bs-accordion-btn-padding-y: 8px;
  --bs-accordion-btn-focus-box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background: #fafafa;
}

.accordion-button::after {
  background-image: url("../../assets/faq/plus.svg");
  background-repeat: no-repeat;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../assets/faq/minus.svg");
  background-repeat: no-repeat;
}

.accordion-item {
  margin-bottom: 25px;
  background: #fafafa;
  border-left: 8px solid #f4b701;
  border-radius: 10px;
}

.underline1 {
  width: 150px;
  height: 2px;
  background-color: red;
}
