/* mobile  */

@media screen and (min-width: 320px) and (max-width: 450px) {
  #partner {
    background-size: cover !important;
  }
  .one-img {
    width: 50% !important;
  }
  .partner-col {
    transform: none !important;
    margin-bottom: 50px !important;
  }
  .points p {
    font-size: 20px !important;
  }
  .onescribe-site-link {
    font-size: 1rem;
  }
  .point-icon {
    margin-right: 15px !important;
    width: 20px !important;
  }
  .collabe-head {
    font-size: 1.25rem !important;
    padding: 30px 0px 20px 0px !important;
  }
  .underarrow {
    width: 50% !important;
  }
  .partner-contents {
    padding: 60px 0px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  #partner {
    background-size: cover !important;
  }
  .one-img {
    width: 50% !important;
  }
  .partner-col {
    transform: none !important;
    margin-bottom: 50px !important;
    padding-top: 100px !important;
  }

  .collabe-head {
    padding-top: 40px !important;
  }

  .partner-details {
    transform: translateY(60%) !important;
  }
}

#partner {
  background-image: url("../../assets/partner/partnerbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.partner-contents {
  padding: 100px 0px;
}

.partner-col {
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(0%);
  padding-top: 50px !important;
}

.partner-details {
  position: absolute;
  top: 0;
  transform: translateY(35%);
}

.partner {
  display: grid;
}

.underarrow {
  vertical-align: initial;
}

.points {
  display: grid;
  justify-content: center;
  text-align: left;
  padding-top: 25px;
}

.points p {
  color: #000;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.onescribe-site-link {
  color: #000;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
}

.point-icon {
  margin-right: 20px;
}

.collaborate-col {
  border-radius: 33px;
  background: var(
    --redish-yellow,
    linear-gradient(316deg, #ff3d00 3.99%, #fcb045 100%)
  );
  
}

.collabe-head {
  color: #131313;
  text-align: center;
  font-family: "Maitree";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 60px;
  padding-bottom: 35px;
}

.textfield-div {
  margin-bottom: 20px;
  display: grid;
}

.input-field {
  border-radius: 10px;
  background: #fafafa;
  width: 88%;
  height: 40px;
  border: none;
  outline: none;
  padding: 15px 15px;
  caret-color: #ffbe00;
  margin: auto;
}

.input-msg {
  border-radius: 10px;
  background: #fafafa;
  width: 88%;
  border: none;
  outline: none;
  padding: 15px 15px;
  caret-color: #ffbe00;
  margin: auto;
}

::placeholder {
  color: #131313;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  opacity: 0.8;
}

.collabe-button {
  color: #fafafa;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  background: #131313 !important;
  outline: none;
  border: none;
  padding: 5px 25px;
}

.collabe-btn {
  padding-top: 15px;
  padding-bottom: 50px;
}

.underarrow,
.point-icon {
  user-select: none;
  -webkit-user-drag: none;
}

.error-text {
  color: #d00000;
}
.partners-form {
  padding: 0px 50px;
}
