#privacy .navbar {
  display: none;
}

#termsandconditions .navbar {
  display: none;
}

#refund-and-cancellation-policy .navbar {
  display: none;
}

#refund-and-cancellation-policy{

  margin-top: 100px;
  margin-bottom: 50px;
}

.privacy-head,
.Terms-head,
.refund-head {
  margin: 25px 0px;
}

.privacy-content,
.Terms-content,
.refund-content,
.partner-content {
  color: #131313;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
}
.privacy-policy-container {
  text-align: start;
  margin-top: 100px;
  margin-bottom: 50px;
}
.terms-condition-container {
  text-align: start;
  margin-top: 100px;
}
