/* mobile  */

@media screen and (max-width: 450px) {
  #blog {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding-bottom: 30px !important;
  }
  .blog-img {
    width: 335px !important;
    height: 220px !important;
  }
  .bloger-name {
    font-size: 14px !important;
  }
  .blog-person {
    padding: 3px 8px !important;
  }
  .blog-head {
    font-size: 1.12rem !important;
  }
  .blog-mainhead {
    padding: 30px 0px 20px 0px !important;
  }
  .blog-col { 
    width: 334px !important;
    min-height: 550px !important;
  }
  .see-more {
    margin-bottom: 20px;
  }
  
}
@media(min-width: 700px) {
  .blog-img {
    width: 464px !important;
  }
}
@media(min-width: 500px) {
  .blog-img {
    width: 464px !important;
  }
}
@media(min-width: 1024px) {
.blog-col {
  width: 334px !important;
}
.blog-img {
  width: 335px !important;
  height: 220px !important;
}
}

/* full hd  */

@media screen and (min-width: 1400px) {
  .blog-img {
    width: 465px !important;
    height: 320px !important;
  }
  .blog-col {
    width: 466px !important;
  }
  .see-more {
    margin-bottom: 60px;
  }
}

#blog {
  background-image: url("../../assets/blog/blogbg.png");
  background-repeat: repeat;
  background-size: contain;
}

.blog-row {
  border-radius: 714px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(244, 183, 1, 0.48) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}

.blog-mainhead {
  padding: 40px 0px 51px 0px;
}

.blog-col {
  width: 466px;
  background-color: #fafafa;
  box-shadow: 0 4px 21px rgb(32 16 16 / 15%);
  padding-bottom: 20px;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 20px;
  max-height: 660px;
  min-height: 650px;
}

.blog-data {
  position: relative;
}

.blog-img {
  width: 435px;
  height: 320px;
  position: relative;
  border-radius: 20px;
  object-fit: cover;
  user-select: none;
  -webkit-user-drag: none;
}

.blog-person {
  border-radius: 50px;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 9px;
  margin-bottom: 10px;
}

.photo img {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  object-fit: cover;
}

.bloger-name {
  color: #000;
  text-align: center;
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.date {
  color: #515151;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  padding: 5px 26px;
}

.underline {
  height: 1px;
  width: 100%;
  background-color: #d4d4d4;
}

.blog-head {
  color: #131313;
  font-family: "Poppins";
  font-size: 1.38rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding: 5px 26px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.blog-content {
  color: #545454;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 5px 26px;
  /* white-space: nowrap;
  overflow: hidden;  */
  text-overflow: ellipsis;
  margin-bottom: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center; 
}

.blog-btn {
  /* margin-top: 25px; */
}

.blog-button {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 50px;
  background: #f4b701 !important;
  outline: none;
  border: none;
  padding: 5px 20px;
}

.see-more {
  color: #131313 !important;
  text-align: center;
  font-family: "Poppins";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 20px;
  border: 1px solid #131313 !important;
  background-color: white !important;
  padding: 5px 20px;
  margin-bottom: 60px;
}

.blogger {
  user-select: none;
  -webkit-user-drag: none;
}
