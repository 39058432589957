/* mobile  */

@media screen and (max-width: 450px) {
  .blogpage-head {
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }
  .personal-details {
    margin-top: 10px !important;
  }
  .blogpage-image {
    margin-top: 40px !important;
    margin-bottom: 30px !important;
  }
  .blogpage-content {
    line-height: 28px !important;
  }
  .blogpage-img {
    width: 100% !important;
    height: 350px !important;
  }
}

#blogpage {
  background: #eeebe4;
  margin-top: 73px;
}

.blogpage-section {
  margin: 15px 0px 50px 0px;
}

.blogpage-head {
  color: #131313;
  font-family: "Maitree";
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 25px;
}

.personal-photo img {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  object-fit: cover;
}

.author-name {
  color: #131313;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.personal-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator-span {
  margin: 0px 15px;
}

.blogpage-image {
  margin-top: 60px;
  margin-bottom: 50px;
}

.blogpage-img {
  width: 100%;
  height: 674px;
  object-fit: cover;
  border-radius: 20px;
  user-select: none;
  -webkit-user-drag: none;
}

.blogpage-content {
  color: #131313;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  width: 100%;
  margin: auto;
}

.next-btn {
  margin-bottom: 80px;
  margin-top: 50px;
}

.next-button {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 50px;
  background: #f4b701 !important;
  outline: none;
  border: none;
  padding: 5px 20px;
}

.personal-img {
  user-select: none;
  -webkit-user-drag: none;
}
.download-button {
  color: #fafafa;
  border-radius: 9px;
  background: #f4b701;
  outline: none;
  border: none;
  padding: 10px 16px;
  margin-bottom: 20px;
}
