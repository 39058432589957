/* mobile  */

@media screen and (max-width: 800px) {
  .nav-link {
    margin-right: 0px !important;
  }
  .navlinks {
    margin: 10px 0px !important;
  }
  .navbar-button {
    padding: 8px 14px !important;
  }
}

/* Sticky */
.navbar.nav-sticky {
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  position: fixed !important;
  width: 100%;
  top: 0 !important;
  margin: 0;
  z-index: 100;
  transition: 0.5s;
  /* transition-timing-function: linear; */
  color: #ff3d00;
}

#homepage {
  background: #fafafa;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.25);
  transition: 0.8s ease-out;
  transition-timing-function: linear;
}

.Brand-logo {
  user-select: none;
  -webkit-user-drag: none;
}

.nav-link {
  color: #131313;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-right: 38px; */
}

.nav-link.active {
  font-family: "Poppins";
  font-weight: 400;
  color: #dfa600 !important;
}

.nav-btn {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
}

.navbar-button {
  color: #fafafa;
  border-radius: 9px;
  background: #2270d6;
  outline: none;
  border: none;
  padding: 10px 16px;
}

.navlinks {
  align-items: center;
}
.store-download-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.message-container {
  /* display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.googleplay {
  width: 37%;
}

.appstore {
  width: 30%;
}

.store-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-open {
  overflow: visible !important;
  overflow: initial !important;
  padding-right: 0 !important;
}

.modal-content{
  background-image: url("../../assets/partner/partnerbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

