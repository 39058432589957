/* mobile  */

@media screen and (max-width: 900px) {
  #footer {
    background-size: cover !important;
  }
  .footer-details {
    display: grid !important;
    justify-content: center !important;
    padding: 60px 0px !important;
  }
  .store-icon-div {
    display: flex;
  justify-content: center;
    gap: 5px;
  }
  .address-section,
  .scribe-section,
  .help-section,
  .media-section {
    text-align: center !important;
  }
  .scribe-section,
  .help-section,
  .media-section {
    margin-top: 20px !important;
  }
  .phone {
    display: flex !important;
    justify-content: center !important;
  }
  .media-icons {
    justify-content: center !important;
  }
  .phone a {
    margin-left: 10px !important;
    margin: auto !important;
  }
}

/* full hd  */

@media screen and (min-width: 1600px) {
  #footer {
    background-size: cover !important;
  }
}

#footer {
  background-image: url("../../assets/footer/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-details {
  display: flex;
  justify-content: space-evenly;
  padding: 90px 0px;
}

.address-section,
.scribe-section,
.help-section,
.media-section {
  text-align: left;
}

.address a,
.mail a,
.phone a,
.scribe-links a,
.help-links a {
  color: #fafafa;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
}

.address a:hover {
  color: #f4b701;
}

.mail a:hover {
  color: #f4b701;
}
.phone a:hover {
  color: #f4b701;
}

.scribe-links a:hover {
  color: #f4b701;
}

.help-links a:hover {
  color: #f4b701;
}

.phone {
  display: grid;
}

.phone a {
  margin-bottom: 10px;
}

.footer-subhead {
  color: #fafafa;
  font-family: "Poppins";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.media-icons {
  display: flex;
}

.f-media {
  margin-right: 15px;
}

.copyright {
  color: #131313;
  text-align: center;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background-color: white;
  padding: 20px 0px;
}

.footer-logo,
.facebook-img,
.insta-img,
.linked-img,
.twitter-img {
  user-select: none;
  -webkit-user-drag: none;
}

.facebook-img :hover {
  color: #f4b701 !important;
}

.insta-img:hover {
  color: #f4b701 !important;
}
.linked-img:hover {
  color: #f4b701 !important;
}

.twitter-img:hover {
  color: #f4b701 !important;
}

.scribe-links p,
.help-links p {
  margin-bottom: 8px;
}

.tag-head {
  color: #fafafa;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px;
  text-decoration: none;
}

.store-icon-div {
  display: flex;
  gap: 5px;
}
.store-icon {
  width: 100px;
  height: 35px;
}
