/* mobile  */

@media screen and (min-width: 320px) and (max-width: 450px) {
  .contact-cartoon {
    width: 100% !important;
  }
  .form-head {
    text-align: center !important;
    font-size: 1.25rem !important;
    padding-top: 0px !important;
    transform: none !important;
  }
  .contact-head {
    padding: 30px 0px 20px 0px !important;
  }
  .contact-form {
    margin-top: 40px !important;
  }
  .triangle-art2 {
    position: absolute;
    left: 15% !important;
  }
  .form-column {
    background-image: none !important;
  }
  .form-head {
    background-position: right !important;
  }
  .contact-form {
    background-image: url("../../assets/contact/right1.svg"), none !important;
  }
  .form-container {
    margin-left: 60px !important;
  }
}

/* tab  */

@media screen and (min-width: 540px) and (max-width: 800px) {
  .contact-cartoon {
    width: 100% !important;
  }
  .form-head {
    text-align: center !important;
    padding-top: 0px !important;
    transform: none !important;
  }
  .contact-form {
    margin-top: 40px !important;
  }
  .triangle-art2 {
    position: absolute;
    left: 15% !important;
  }
  .form-column {
    background-image: none !important;
  }
  .form-head {
    background-position: right !important;
  }
  .contact-form {
    background-image: url("../../assets/contact/right1.svg"), none !important;
  }
}

#contact {
  background-image: url("../../assets/contact/contactbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-section {
  border-radius: 30px;
  background: var(
    --redish-yellow,
    linear-gradient(316deg, #ff3d00 3.99%, #fcb045 100%)
  );
  margin-bottom: 70px;
  overflow: hidden;
}

.contact-form {
  background-image: url("../../assets/contact/right1.svg"),
    url("../../assets/contact/Polygon.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom, top;
}

.contact-head {
  padding: 40px 0px 25px 0px;
}

.form-head {
  color: #131313;
  text-align: left;
  font-family: "Maitree";
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 60px;
  padding-left: 60px;
  padding-bottom: 15px;
  position: relative;
  left: 0%;
  transform: translateX(10%);
  background-image: url("../../assets/contact/round.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.contact-input-field {
  border-radius: 10px;
  background: #fafafa;
  width: 80%;
  border: none;
  outline: none;
  caret-color: #ffbe00;
  margin: auto;
  position: relative;
}


.contact-input-msg {
  border-radius: 8px;
  background: #fafafa;
  width: 80%;
  border: none;
  outline: none;
  caret-color: #ffbe00;
  margin: auto;
}

.contact-textfield-div {
  margin-bottom: 20px;
  display: grid;
}

.name-field {
  position: relative;
}

.alert {
  width: 50%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: red;
}

.contact-cartoon {
  user-select: none;
  -webkit-user-drag: none;
}

.contact-error-text {
  color: #d00000;
  font-size: 1rem;
  text-align: left;
}

.contact-btn {
  padding-top: 15px;
  padding-bottom: 80px;
}

.row {
  background-image: url("../../assets/contact/rightbottom.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;

}

.triangle-art {
  position: absolute;
  left: 12%;
}

.triangle-art2 {
  position: absolute;
  right: 45%;
}

.form-column {
  background-image: url("../../assets/contact/topline.svg");
  background-repeat: no-repeat;
  background-position-x: right;
}
.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  
}

.form-container {
  margin-left: 100px;
}