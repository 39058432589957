/* phone */
#customers {
  padding: 82px 0px;
  background-image: url("../../assets/customers/customer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.background-one {
  position: relative;
  width: 300px;
  height: 250px;
  margin-top: 50px;
  left: 0px;
  background-image: url("./../../assets/customers/one_bg.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.customers-details-div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 22px;
  text-align: start;
  width: 300px;
  height: 300px;
}

.customers-container {
  position: relative !important;
}
.customers-container::after {
  position: absolute !important;
  top: -50px;
  left: 30px;
  content: url(./../../assets/customers/star.svg);
}
.customers-heading {
  font-family: poppins;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  background: linear-gradient(316deg, #ff3d00 4%, #fcb045 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: start;
}
.customers-details {
  display: flex;
  gap: 8px;
  font-family: poppins;
  font-weight: 500;
  font-size: 18px;
}
.first-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-customer {
  position: relative;
  width: 300px;
  height: 300px;
  background-image: url("./../../assets/business/circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
}
.phone-img {
  position: absolute;
  width: 254px;
  height: 330px;
  right: 0px;
  left: 0;
  top:0;
}

@media (min-width: 662px) {
  .background-one {
    width: 600px;
    height: 400px;
    top: 90px;
  }
  .customers-details-div {
    justify-content: center;
  }
  .customers-container::after {
    position: absolute !important;
    top: -30px;
    left: 30px;
    content: url(./../../assets/customers/star.svg);
  }
  .circle-customer {
    width: 600px;
    height: 600px;
  }
  .phone-img {
    width: 304px;
    height: 530px;
  }
}
/* large */

@media (min-width: 900px) {
  .background-one {
    width: 100%;
    height: 755px;
  }
  .customers-container::after {
    top: -30px;
    left: -30px;
    content: url(./../../assets/customers/star1.svg);
  }
  .circle-customer {
    width: 600px;
    height: 600px;
  }
  .phone-img {
    position: absolute;
    width: 304px;
    height: 530px;
  }
}

@media (min-width: 1024px) {
  .circle-customer {
    width: 600px;
    height: 600px;
  }
  /* .phone-img {
    width: 304px;
    height: 530px;
  } */
  .background-one {
    width: 500px;
    height: 600px;
    top: 100px;
  }
  .customers-details-div {
    justify-content: center;
    left: 0px;
    top: 10px;
  }
  .customers-container::after {
    top: 60px;
    left: 60px;
    content: url(./../../assets/customers/star2.svg);
  }
  .circle-customer {
    width: 700px;
    height: 700px;
  }
  .phone-img {
    width: 564px;
    height: 735px;
    left: 20px;
    top: 40px;
}
  /* .phone-img {
    width: 484px;
    height: 655px;
  } */
}
