/* mobile  */

@media screen and (max-width: 450px) {
  .main-heading {
    font-size: 1.5rem !important;
  }
}

body {
  font-size: 14px;
}


.main-heading {
  color: #131313;
  text-align: center;
  font-family: "Maitree";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
